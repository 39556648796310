<template>
  <div class="page">
    <v-list
      ref="list"
      :isAdd="true"
      :headers="headers"
      :tableUrl="tableUrl"
      :searchParam="searchParam"
    >
      <template #searchSlot>
        <v-input
          label="社群名称"
          v-model="searchParam.associationName"
          placeholder="请输入社群姓名"
        />
        <v-input
          label="负责人姓名"
          v-model="searchParam.directorName"
          placeholder="请输入负责人姓名"
        />
        <v-input
          label="负责人手机号"
          v-model="searchParam.directorMobile"
          placeholder="请输入负责人手机号"
        />
        <v-select
          clearable
          :options="applyStatus"
          v-model="searchParam.auditStatus"
          label="审核状态"
        />
      </template>
      <template #operateSlot="scope">
        <v-button
          text="审核"
          v-if="scope.row.auditStatus == 0"
          type="text"
          @click="audit(scope.row)"
        />
        <v-button text="查看" v-else type="text" @click="check(scope.row)" />
      </template>
    </v-list>
    <el-dialog
      :close-on-click-modal="false"
      :visible.sync="dialogFormVisible"
      width="50%"
    >
      <el-form label-position="right" label-width="100px">
        <el-form-item label="社群名称">
          <v-input
            disabled
            clearable
            class="filter-item"
            v-model="temp.associationName"
          ></v-input>
        </el-form-item>
        <el-form-item label="关键词">
          <v-input
            disabled
            clearable
            class="filter-item"
            v-model="temp.content"
          ></v-input>
        </el-form-item>
        <el-form-item label="社群介绍" prop="neighborhoodText">
          <v-input
            disabled
            clearable
            class="filter-item"
            v-model="temp.description"
          ></v-input>
        </el-form-item>
        <el-form-item label="社群图片">
          <img style="width: 100%" :src="temp.picture" alt="" />
        </el-form-item>
        <el-form-item label="社群负责人">
          <v-input
            disabled
            clearable
            class="filter-item"
            v-model="temp.directorName"
          ></v-input>
        </el-form-item>
        <el-form-item label="负责人手机号">
          <v-input
            disabled
            clearable
            class="filter-item"
            v-model="temp.directorMobile"
          ></v-input>
        </el-form-item>
        <el-form-item label="审核状态">
          <v-select
            :disabled="type_d == 'check'"
            :options="applyStatus_dOps"
            v-model="temp.auditStatus"
          />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer dialogBottomBtn">
        <v-button
          size="medium"
          @click="dialogFormVisible = false"
          text="取消"
        ></v-button>
        <v-button
          v-if="type_d == 'audit'"
          size="medium"
          type="primary"
          @click="submit()"
          text="保存"
        ></v-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getAuditListUrl, getDetailUrl, auditUrl } from "./api.js";
import {
  applyStatus,
  applyStatusMap,
  applyStatusOps,
  applyStatus_d,
  applyStatus_dMap,
  applyStatus_dOps,
} from "./map.js";
import { createAlinkVNode, createImgVNode } from "@/utils/utils.js";
export default {
  name: "associationApplyOrder",
  data() {
    return {
      type_d: "",
      applyStatus_dOps: applyStatus_dOps(),
      applyStatus,
      tableUrl: getAuditListUrl,
      dialogTitle: "",
      dialogFormVisible: false,
      searchParam: {
        associationName: null,
        directorName: null,
        directorMobile: null,
        auditStatus: null,
      },
      headers: [
        {
          prop: "picture",
          label: "社群图片",
          formatter: (row, prop) => createImgVNode(this, row, prop),
        },
        {
          prop: "associationName",
          label: "社群名称",
        },
        {
          prop: "content",
          label: "关键词",
        },
        {
          prop: "directorName",
          label: "社群负责人",
        },
        {
          prop: "directorMobile",
          label: "负责人手机号",
        },
        {
          prop: "description",
          label: "社群介绍",
        },
        {
          prop: "auditStatus",
          label: "审核状态",
          formatter: (row, prop) => {
            return applyStatusMap[row.auditStatus];
          },
        },
      ],
      temp: {
        id: null,
        associationName: null,
        description: null,
        content: null,
        picture: null,
        directorName: null,
        directorMobile: null,
        auditStatus: null,
        associationId: null,
      },
    };
  },
  methods: {
    submit() {
      if (!this.temp.auditStatus || this.temp.auditStatus == "") {
        this.$message.error("请选择审核状态！");
        return;
      }
      let params = {
        id: this.temp.id,
        auditStatus: this.temp.auditStatus,
        associationId: this.temp.associationId,
      };
      this.$axios
        .post(`${auditUrl}`, this.$qs.stringify(params))
        .then((res) => {
          if (res.code === 200) {
            this.$message.success("审核成功！");
            this.dialogFormVisible = false;
            this.$refs.list.search();
          }
        });
    },
    audit(data) {
      this.temp.id = data.id;
      this.type_d = "audit";
      this.getDetail(data.id, "audit");
      this.dialogFormVisible = true;
    },
    check(data) {
      this.type_d = "check";
      this.getDetail(data.id, "check");
      this.dialogFormVisible = true;
    },
    getDetail(id, type) {
      let params = {
        id: id,
      };
      this.$axios
        .get(`${getDetailUrl}`, {
          params: params,
        })
        .then((res) => {
          if (res.code === 200) {
            this.temp.associationName = res.data.associationName;
            this.temp.description = res.data.description;
            this.temp.content = res.data.content;
            this.temp.picture = res.data.picture;
            this.temp.directorName = res.data.directorName;
            this.temp.directorMobile = res.data.directorMobile;
            this.temp.associationId = res.data.associationId;
            if (type == "audit") {
              this.temp.auditStatus = null;
            } else {
              this.temp.auditStatus = res.data.auditStatus;
            }
          }
        });
    },
  },
};
</script>

<style lang="less" scoped>
.page {
  box-sizing: border-box;
  height: 100%;
}
</style>
